<template>
  <div>
    <ContentHeader :title="$gettext('Create candidate')" />
    <form @submit.prevent="handleSubmit">
      <div class="card">
        <div class="card-body">
          <div class="row">
            <FormInput
              class="col-md-6"
              v-model="payload.first_name"
              :label="$gettext('First name')"
              field="first_name"
              required
            />

            <FormInput
              class="col-md-6"
              v-model="payload.last_name"
              :label="$gettext('Last name')"
              field="last_name"
              required
            />

            <FormInput
              class="col-md-6"
              v-model="payload.email"
              :label="$gettext('Email')"
              field="email"
              required
            />

            <FormInput
              class="col-md-6"
              v-model="payload.phone_number"
              :label="$gettext('Phone number')"
              field="phone_number"
            />
          </div>
        </div>
        <div class="card-footer">
          <router-link :to="{ name: 'candidates' }" class="btn btn-danger"
            ><translate>Cancel</translate></router-link
          >
          <SubmitButton :label="$gettext('Save')" class="btn btn-info ml-1" />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormInput from "@/components/FormGroups/FormInput";
import SubmitButton from "@/components/SubmitButton";
import ContentHeader from "@/components/ContentHeader";

export default {
  components: { FormInput, SubmitButton, ContentHeader },
  data: () => ({
    payload: { first_name: "", last_name: "", email: "", phone_number: "" }
  }),
  methods: {
    ...mapActions("candidates", ["create"]),
    ...mapActions("form", ["submit"]),
    handleSubmit() {
      this.submit(() => this.create(this.payload)).then(({ data }) =>
        this.$router.push({
          name: "candidate-details",
          params: { candidateId: data.id }
        })
      );
    }
  }
};
</script>
